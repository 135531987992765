import React from "react"
import QueueAnim from "rc-queue-anim"
import ScrollAnim from "rc-scroll-anim"
import "./sgnOverview.scss"
import posAndOp from "../../images/sgnOverview/posAndOp.svg"
import UseIsMobile from "../../hooks/UseIsMobile"

export default function PosAndOpRollup() {
  const [isMobile] = UseIsMobile()
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  return (
    <div className="posAndOp">
      <ScrollOverPack playScale={0.3} always={false} key="posAndOp">
        <div className="posAndOp-body">
          <MyQueueAnim duration={3000} type="bottom">
            <div className="posAndOp-title" key="posAndOp-title">
              PoS and Optimistic-rollup-like Security Models
            </div>
            <div className="posAndOp-describe" key="posAndOp-describe">
              <div className="posAndOp-des-item">
                Inter-chain dApps built on top of Celer can choose from or
                combine two security models with different trade-offs on delay.
              </div>
              <div className="posAndOp-des-item">
                By default, inter-chain dApps rely on the security of the State
                Guardian Network, which processes messages routed from one chain
                to another without delay. As a Proof-of-Stake (PoS) blockchain
                built on Tendermint, the SGN offers security similar to that of
                Cosmos or Polygon. With CELR as the staking asset, the level of
                economic security grows with the value of staked CELR. If a
                guardian acts maliciously, its staked CELR will be slashed
                entirely by the consensus protocol.
              </div>
              <div className="posAndOp-des-item">
                On top of that, an additional layer of optimistic-rollup
                protection ensures limited risk exposure even under extreme
                circumstances, e.g. when all the guardians behave maliciously.{" "}
              </div>
              In these scenarios, instead of processing a message routed by the
              SGN instantly every time, an inter-chain dApp can inject a
              customized delay buffer in high-risk scenarios, and run an
              independent watchtower service to double-validate the message
              received. If any inconsistency is detected, it stops the message
              from being processed before the delay expires.
            </div>
          </MyQueueAnim>
          <div className="posAndOp-img">
            <MyQueueAnim duration={3000} delay={350} type="bottom">
              <img src={posAndOp} alt=" " key="posAndOp-img" />
            </MyQueueAnim>
          </div>
        </div>
      </ScrollOverPack>
    </div>
  )
}
