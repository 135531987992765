import React from "react"
import QueueAnim from "rc-queue-anim"
import ScrollAnim from "rc-scroll-anim"
import "./messageOverview.scss"
import overviewPng from "../../images/tech/messageOverview.svg"
import messageOverviewMin from "../../images/tech/messageOverview-min.svg"
import UseIsMobile from "../../hooks/UseIsMobile"
/* eslint-disable */

export default function MessageOverview() {
  const [isMobile] = UseIsMobile()
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  return (
    <div className="message-overview">
      <ScrollOverPack playScale={0.5} always={false} key="message-overview">
        <div className="message-body">
          <div className="message-overview-title">
            <MyQueueAnim duration={3000} type="bottom">
              <div
                className="message-overview-main-title"
                key="message-overview-main-title"
              >
                Celer Inter-chain Messaging Overview
              </div>
            </MyQueueAnim>
            <MyQueueAnim duration={3000} delay={300} type="bottom">
              <div
                className="message-overview-subtitle"
                key="message-overview-subtitle"
              >
                Celer uses{" "}
                <span
                  className="message-link"
                  onClick={() => {
                    window.open(
                      "https://github.com/celer-network/sgn-v2-contracts/tree/main/contracts/message/messagebus",
                      "_blank"
                    )
                  }}
                >
                  MessageBus{" "}
                </span>
                contracts deployed on chains paired with the State Guardian
                Network to enable seamless multi-blockchain interoperability. To
                send a message or invoke a smart contract function across
                chains, a user or a dApp first send a message with a structured
                header and arbitrary binary payload to a MessageBus contract on
                the source chain. Then the validator, the State Guardian
                Network, will reach a consensus and generate a stake-weighed
                multi-signature attestation on the existence of such a message.
                This attestation is then relayed to the destination chain via an{" "}
                <span
                  className="message-link"
                  onClick={() => {
                    window.open(
                      "https://im-docs.celer.network/developer/development-guide/message-executor",
                      "_blank"
                    )
                  }}
                >
                  Executor{" "}
                </span>
                subscribing to the message. On the destination chain, the same
                MessageBus contract checks the validity of the message and
                triggers the corresponding logic associated with the message
                either immediately or after a timeout. For a step-by-step
                example walkthrough, please refer to{" "}
                <span
                  className="message-link"
                  onClick={() => {
                    window.open(
                      "https://im-docs.celer.network/developer/architecture-walkthrough/end-to-end-workflow",
                      "_blank"
                    )
                  }}
                >
                  Celer IM documentation.
                </span>
              </div>
            </MyQueueAnim>
          </div>
        </div>

        {!isMobile && (
          <div className="message-overview-img">
            <div className="message-overview-img-body">
              <MyQueueAnim duration={3000} type="bottom" delay={500}>
                <img
                  src={overviewPng}
                  alt=""
                  className="ovbodyImg"
                  key="message-overview-img"
                />
              </MyQueueAnim>
            </div>
          </div>
        )}
      </ScrollOverPack>
      {!isMobile && (
        <div className="imgBg">
          <div className="imgBgLeft"></div>
          <div className="imgBgRight"></div>
        </div>
      )}
      {isMobile && (
        <div className="message-overview-img">
          <img
            src={messageOverviewMin}
            alt=""
            className="ovbodyImg"
            key="message-overview-img"
          />
        </div>
      )}
    </div>
  )
}
