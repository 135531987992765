import React from "react"
import QueueAnim from "rc-queue-anim"
import "./hero.scss"
import UseIsMobile from "../../hooks/UseIsMobile"

export default function Hero() {
  const [isMobile] = UseIsMobile()
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  return (
    <div className="technology-hero">
      <MyQueueAnim duration={2000} type="bottom" key="1">
        <div className="technology-hero-title" key="technology-hero-title">
          A<br />
          Multi-blockchain
          <br />
          Operating
          <br />
          System
        </div>
      </MyQueueAnim>
    </div>
  )
}
