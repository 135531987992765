import React from "react"
import QueueAnim from "rc-queue-anim"
import ScrollAnim from "rc-scroll-anim"
import "./sgnOverview.scss"
import sgnOverview from "../../images/sgnOverview/sgnOverview.svg"

import UseIsMobile from "../../hooks/UseIsMobile"

export default function SGNOverview() {
  const [isMobile] = UseIsMobile()
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  return (
    <div className="sgnOverview">
      <ScrollOverPack playScale={0.22} always={false} key="sgnOverview">
        <div className="sgnOverview-body">
          <MyQueueAnim duration={3000} type="bottom">
            <div className="sgnOverview-title" key="sgnOverview-title">
              State Guardian Network Overview
            </div>
            <div className="sgnOverview-describe" key="sgnOverview-describe">
              The State Guardian Network (SGN) is a Proof-of-Stake (PoS)
              blockchain built on Tendermint. The SGN serves as the message
              router between different blockchains for Celer products in general
              message passing and cross-chain fund transfers. Celer cBridge and
              the Inter-chain Messaging Framework (Celer IM) both leverage the
              SGN in cross-chain transactions for its PoS security, fast
              finality, and low cost.
            </div>
          </MyQueueAnim>
          <MyQueueAnim duration={3000} type="bottom" delay={500}>
            <div className="sgnOverview-img" key="sgnOverview-img">
              <img src={sgnOverview} alt=" " />
            </div>
          </MyQueueAnim>
        </div>
      </ScrollOverPack>
    </div>
  )
}
