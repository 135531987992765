import React from "react"
import QueueAnim from "rc-queue-anim"
import ScrollAnim from "rc-scroll-anim"
import "./valueCapture.scss"
import technologyCaptureBgPng from "../../images/tech/valueCapture.svg"
import { technologyLinks } from "../../constants"
import UseIsMobile from "../../hooks/UseIsMobile"
/* eslint-disable */

export default function ValueCapture() {
  const [isMobile] = UseIsMobile()
  const ScrollOverPack = isMobile ? "div" : ScrollAnim.OverPack
  const MyQueueAnim = isMobile ? "div" : QueueAnim
  return (
    <div className="value-capture">
      <ScrollOverPack playScale={0.5} always={false} key="value-capture">
        <div className="value-capture-body">
          <MyQueueAnim duration={3000} type="bottom">
            <div className="value-capture-title" key="value-capture-title">
              CELR Token Utilities and Value Capture
            </div>
            <div className="value-capture-content" key="value-capture-content">
              <div className="value-capture-content-item">
                The CELR token is the protocol token for Celer Network. CELR is
                used as a staking token in the SGN to ensure the economic
                security of Celer IM.{" "}
                <span
                  className="message-link"
                  onClick={() => {
                    window.open(
                      technologyLinks.valueCapture.stakeCELR,
                      "_blank"
                    )
                  }}
                >
                  Stake your CELR here.
                </span>
              </div>
              <div className="value-capture-content-item">
                Similar to using any other PoS blockchain, to use the SGN for
                message routing, users must pay a fee to SGN validators and
                their stake delegators to incentivize their work in securing the
                network.{" "}
              </div>
              <div className="value-capture-content-item">
                In addition to these fees, the SGN, as a tendermint blockchain,
                also issues block rewards for validators that produce a block.
                CELR tokens have to be staked in the SGN in order to receive
                these rewards. With staking, validators and stakers actively
                participate in the operation of the protocol and undertake the
                risk of slashing: if a validator is malfunctioning or is
                compromised to act maliciously, part of or all CELR delegated to
                or staked by this validator will be slashed. Unstaked CELR
                receive no rewards of any kind.
              </div>
              <div className="value-capture-content-item">
                CELR is also the governance token needed to update and change
                protocol parameters such as supported chains and tokens, fee
                configurations for each chain and token, and new modules that
                will be added. Any upgrade or changes to the SGN as well as
                these parameters require governance through the SGN with the
                CELR token.
              </div>
            </div>
          </MyQueueAnim>
          <div className="value-capture-peocess">
            <MyQueueAnim duration={3000} delay={350} type="bottom">
              <img
                src={technologyCaptureBgPng}
                alt=""
                key="value-capture-peocess"
              />
            </MyQueueAnim>
          </div>
        </div>
      </ScrollOverPack>
    </div>
  )
}
